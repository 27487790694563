
import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Sortable, { MoveEvent, SortableEvent } from 'sortablejs';

import Store from '@/store';

import TaskService from '@/services/task.service';
import TaskGroupService from '@/services/task-group.service';

import TaskStatus from '@/models/task-status.model';
import Task from '@/models/task.model';
import User from '@/models/user.model';

import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import Table from '@/components/draggable-table/Table.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import AddTask from '@/components/project/tasks/Add.vue';

interface AddTaskProps {
	expectedEnd: Date;
	expectedStart: Date;
	point: number;
	responsible: number[];
	status: TaskStatus;
	title: string;
}

export default defineComponent({
	props: ['group', 'everyTask'],

	emits: ['open-overlay', 'update-title', 'toggleSelected', 'drop', 'delete'],

	components: {
		SVG,
		Loader,
		Table,
		Checkbox,
		AddTask,
	},

	setup(props, { emit }) {
		const route = useRoute();
		const user = Store.get('user');
		const statuses = Store.get('statuses');
		const title = ref('');
		const tasks: Ref<Task[]> = ref([]);
		const allTasks: Ref<Task[]> = ref([]);
		const duplicate: Ref<Task | null> = ref(null);
		const tasksInDrag = Store.get('tasksInDrag');
		const isDraggedOver = Store.get('draggingTaskOver');
		const isAdding = ref(false);
		const dragLoader = ref(false);

		const shouldSelectAll = ref(false);

		const shouldAdd = computed(() => {
			if (user.value.cannot('create tasks')) {
				return '';
			}

			if (isAdding.value) {
				return '';
			}

			return 'Stovna uppgávu';
		});

		onMounted(() => {
			title.value = props.group.title;
			allTasks.value = props.everyTask;
			tasks.value = props.group.tasks;

			createSortable();
		});

		watch(
			() => props.group,
			(group) => {
				if (group.tasks.length < tasks.value.length) {
					tasks.value = group.tasks;
				}
			}
		);

		function createSortable() {
			const el = document.getElementById(`group-${props.group.id}`);

			if (el !== null) {
				Sortable.create(el, {
					group: { name: 'tasks', pull: true, put: true },
					animation: 0,
					onMove: (e: MoveEvent) => onDragMove(e),
					onAdd: (e: SortableEvent) => onDragAdd(e),
					onUpdate: (e: SortableEvent) => onDragUpdate(e),
					onEnd: (e: SortableEvent) => onDragEnd(e),
				});
			}
		}

		function handleStatusColor(status: TaskStatus | number) {
			if (typeof status === 'number') {
				const currentStatus = statuses.value.find((value: TaskStatus) => value.id === status);

				return currentStatus.color;
			}

			return status.color;
		}

		function handleStatusTitle(status: TaskStatus | number) {
			if (typeof status === 'number') {
				const currentStatus = statuses.value.find((value: TaskStatus) => value.id === status);

				return currentStatus.title;
			}

			return status.title;
		}

		// Get the User object, given an id
		function getUser(userId: number) {
			if (!userId) {
				return null;
			}

			const users = Store.get('users');

			if (!users.value) {
				return null;
			}

			return users.value?.find((user: User) => user.id == userId);
		}

		// Get the full name of the User
		function getUserFullName(user: User) {
			return `${user?.first_name} ${user?.last_name}`;
		}

		// Add a new Task
		async function addTask(task: AddTaskProps) {
			await TaskService.create({
				project_id: Number(route.params.id),
				title: task.title,
				expected_start: task.expectedStart ? new Date(task.expectedStart).format('%y-%m-%d') : null,
				expected_end: task.expectedEnd ? new Date(task.expectedEnd).format('%y-%m-%d') : null,
				responsible_user_id: task.responsible[0] || null,
				point: task.point,
				task_status_id: task.status?.id || 1,
				task_group_id: props.group.id,
			}).then((response) => {
				if (response) {
					tasks.value = [...tasks.value, response];
				}
			});
		}

		// Set Task to duplicate
		async function duplicateTask(task: Task) {
			duplicate.value = task;
		}

		// Delete a Task
		async function deleteTask(taskId: number) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await TaskService.delete(taskId).then((response) => {
				if (response) {
					tasks.value = tasks.value.filter((task: Task) => task.id != taskId);
					allTasks.value = allTasks.value.filter((task: Task) => task.id != taskId);
				}
			});
		}

		async function renameGroup(title: string) {
			if (title == props.group.title) {
				return;
			}

			await TaskGroupService.update(props.group.id, {
				title,
			}).then((response) => {
				if (response) {
					emit('update-title', title);
				}
			});
		}

		// Delete a Group
		async function deleteGroup() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await TaskGroupService.delete(props.group.id).then((response) => {
				if (response) {
					emit('delete', props.group.id);
				}
			});
		}

		// Select all Tasks
		function selectAll(select: boolean) {
			tasks.value = tasks.value.map((task: Task) => ({
				...task,
				checked: select,
			}));

			emit('toggleSelected', tasks.value);

			shouldSelectAll.value = select;
		}

		// Single select
		function handleChecked(taskId: number, selected: boolean) {
			tasks.value = tasks.value.map((task: Task) => {
				if (task.id === taskId) {
					return { ...task, checked: selected };
				}

				return task;
			});

			let shouldSelectAllHandler = true;

			tasks.value.forEach((task: Task) => {
				if (!task?.checked) {
					shouldSelectAllHandler = false;
				}
			});

			shouldSelectAll.value = shouldSelectAllHandler;

			emit('toggleSelected', tasks.value);
		}

		// Handle drag move
		function onDragMove(e: MoveEvent) {
			if (e.to.id !== e.from.id) {
				e.to.classList.add('hide-ghost');

				// Bulk drag and drop

				// const selected = document.querySelectorAll(`#${e.from.id} .draggable-selected`);
				// if (selected && selected.length > 1) {
				// 	const tasksToDrag: number[] = [];

				// 	selected.forEach((select: Element) => {
				// 		tasksToDrag.push(Number(select.id.replace('task-', '')));
				// 	});

				// 	const draggedId = Number(e.dragged.id.replace('task-', ''));

				// 	if (tasksToDrag.indexOf(draggedId) === -1) {
				// 		tasksToDrag.push(draggedId);
				// 	}

				// 	Store.set('tasksInDrag', tasksToDrag);
				// }

				Store.set('draggingTaskOver', e.to.id);
				return;
			}

			Store.set('tasksInDrag', []);
			Store.set('draggingTaskOver', '');
			return;
		}

		// Update group position
		function onDragUpdate(e: SortableEvent) {
			dragLoader.value = true;

			const groupTasks = [...tasks.value];

			if (e.oldIndex && e.newIndex) {
				const itemToMove = groupTasks[e.oldIndex];

				groupTasks.splice(e.oldIndex, 1);
				groupTasks.splice(e.newIndex, 0, itemToMove);

				(async () => {
					await TaskService.update(itemToMove.id, { title: itemToMove.title, group_index: e.newIndex }).then(
						(response) => {
							if (response) {
								tasks.value = groupTasks;
							}
						}
					);
				})();
			}

			dragLoader.value = false;
			return;
		}

		// Update group position
		function onDragAdd(e: SortableEvent) {
			dragLoader.value = true;

			const currentItemId = Number(e.item.id.replace('task-', ''));
			const itemToMove = allTasks.value.find((task: Task) => task.id === currentItemId);
			const futureGroup = Number(e.to.id.replace('group-', ''));

			if (itemToMove) {
				// Bulk drag and drop

				// if (
				// 	tasksInDrag.value &&
				// 	tasksInDrag.value.length > 1 &&
				// 	tasksInDrag.value.indexOf(currentItemId) !== -1
				// ) {
				// 	const newTasks = allTasks.value.filter((task: Task) => {
				// 		if (tasksInDrag.value.includes(task.id)) {
				// 			return task;
				// 		}
				// 	});

				// 	(async () =>
				// 		await TaskService.bulkUpdate({
				// 			task_ids: tasksInDrag.value,
				// 			task_group_id: futureGroup,
				// 		})
				// 			.then((response) => {
				// 				if (response) {
				// 					tasks.value = [...tasks.value, ...newTasks];
				// 				}
				// 			})
				// 			.finally(() => {
				// 				dragLoader.value = false;
				// 			}))();

				// 	return false;
				// }

				(async () =>
					await TaskService.update(currentItemId, {
						title: itemToMove.title,
						task_group_id: futureGroup,
					})
						.then(() => {
							tasks.value.push(itemToMove);
						})
						.finally(() => {
							dragLoader.value = false;
						}))();
			}

			return false;
		}

		// handle end of drag
		function onDragEnd(e: SortableEvent) {
			dragLoader.value = true;
			document.querySelectorAll('.hide-ghost').forEach((ghost) => ghost.classList.remove('hide-ghost'));
			if (e.to.id !== e.from.id) {
				e.item.remove();

				// Bulk drag and drop

				// if (tasksInDrag.value && tasksInDrag.value.length > 1) {
				// 	if (tasksInDrag.value.length === tasks.value.length) {
				// 		tasks.value = [];
				// 		Store.set('tasksInDrag', []);
				// 		Store.set('draggingTaskOver', '');
				// 		dragLoader.value = false;
				// 		return false;
				// 	}

				// 	const groupTasks = [...tasks.value];
				// 	for (let i = 0; i < tasksInDrag.value.length; i++) {
				// 		const currentItemId = tasksInDrag.value[i];

				// 		const itemIndex = groupTasks.findIndex((task: Task) => task.id === currentItemId);
				// 		if (itemIndex) {
				// 			groupTasks.splice(itemIndex, 1);
				// 		}
				// 	}

				// 	tasks.value = groupTasks;

				// 	Store.set('tasksInDrag', []);
				// 	Store.set('draggingTaskOver', '');
				// 	dragLoader.value = false;
				// 	return false;
				// }

				if (e.oldIndex) {
					tasks.value.splice(e.oldIndex, 1);
				}
			}

			Store.set('tasksInDrag', []);
			Store.set('draggingTaskOver', '');
			dragLoader.value = false;
			return false;
		}

		return {
			// data
			title,
			duplicate,
			tasks,
			isDraggedOver,
			tasksInDrag,
			isAdding,
			dragLoader,
			shouldAdd,
			shouldSelectAll,

			// functions
			getUser,
			getUserFullName,
			addTask,
			duplicateTask,
			deleteTask,
			renameGroup,
			deleteGroup,
			selectAll,
			handleChecked,
			onDragUpdate,
			onDragAdd,
			onDragMove,
			onDragEnd,
			handleStatusColor,
			handleStatusTitle,
		};
	},
});
